var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "selecionar-acesso-page" },
    [
      _c(
        "v-card",
        {
          staticClass: "selecionar-acesso-content",
          attrs: { height: "100vh" }
        },
        [
          _c("strong", [_vm._v("Selecione o acesso para prosseguir")]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v("Apresentante")]),
                _c("th", [_vm._v("Admin")]),
                _c("th")
              ])
            ]),
            _c(
              "tbody",
              _vm._l(_vm.social_users, function(user) {
                return _c("tr", { key: user.id }, [
                  _c("td", [_vm._v(_vm._s(user.cd_apresentante))]),
                  _c("td", [_vm._v(_vm._s(user.is_admin ? "Sim" : "Nao"))]),
                  _c("td", [
                    _c("button", [
                      _vm._v(
                        " Selecionar " + _vm._s(user.cd_apresentante) + " "
                      )
                    ])
                  ])
                ])
              }),
              0
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }