<template>
    <div class="selecionar-acesso-page">
        <v-card class="selecionar-acesso-content" height="100vh">
            <strong>Selecione o acesso para prosseguir</strong>
            <table>
                <thead>
                    <tr>
                        <th>Apresentante</th>
                        <th>Admin</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="user in social_users" :key="user.id">
                        <td>{{ user.cd_apresentante }}</td>
                        <td>{{ user.is_admin ? 'Sim' : 'Nao' }}</td>
                        <td>
                            <button>
                                Selecionar {{ user.cd_apresentante }}
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </v-card>
    </div>
</template>
<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    components: {},
    data() {
        return {};
    },
    computed: {
        social_users() {
            return this.$store.state.user.users;
        }
    },
    methods: {}
});
</script>

<style lang="scss">
.selecionar-acesso-page {
    margin: 10px;
    display: flex;
    width: 100%;
    flex-direction: column;

    .selecionar-acesso-content {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        strong {
            margin-bottom: 20px;
        }
    }

    table {
        border: 1px solid #ccc;
        width: 50%;
        tbody {
            td {
                text-align: center;

                button {
                    background-color: #26c6da;
                    color: #fff;
                    padding: 5px 10px;
                    border-radius: 4px;
                }
            }
        }
    }
}
</style>
